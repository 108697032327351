export const prerender = false;

import {
  OIDC_COOKIES,
  defaultCookieOptions,
  getAuthorizationUrl,
} from "@lib/auth.ts";
import type { APIRoute } from "astro";
import { generateRandomCodeVerifier } from "oauth4webapi";

export enum Params {
  RedirectUrl = "redirect_url",
  Flow = "flow",
}

export const GET: APIRoute = async ({ redirect, request, cookies }) => {
  const url = new URL(request.url);

  const code_verifier = generateRandomCodeVerifier();
  cookies.set(OIDC_COOKIES.CodeVerifier, code_verifier, defaultCookieOptions);

  const flow =
    url.searchParams.get(Params.Flow) === "signup" ? "signup" : "login";

  const state = {
    path: url.searchParams.get(Params.RedirectUrl) || "/",
    flow,
  };

  cookies.set(OIDC_COOKIES.State, state, defaultCookieOptions);

  const authorizationUrl = await getAuthorizationUrl(
    code_verifier,
    JSON.stringify(state),
    flow,
  );

  return redirect(authorizationUrl.toString());
};
