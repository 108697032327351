import { useStore } from "@nanostores/react";
import { Anchor, Toggle } from "@sumup-oss/circuit-ui";
import { $extdev, $testMode } from "src/store/extdev";
import { $merchant } from "src/store/merchant";
import { $user } from "src/store/user";

import { Params } from "src/pages/auth/login";
import styles from "./styles.module.css";

export const TestModeToggle = () => {
  const { data: user, loading: userLoading } = useStore($user);
  const { data: merchant, loading: merchantLoading } = useStore($merchant);
  const testMode = useStore($testMode);
  const { data: extdev, loading: extdevLoading } = useStore($extdev);

  if (userLoading || merchantLoading || merchant?.extdev || extdevLoading) {
    return null;
  }

  // user is not logged in, show the action which to redirect to login
  if (!user) {
    const url = new URL("/auth/login", window.location.origin);
    url.searchParams.set(Params.RedirectUrl, "/extdev");
    url.searchParams.set(Params.Flow, "signup");
    return (
      <Anchor href={url.pathname + url.search} className={styles.button}>
        Create test account
      </Anchor>
    );
  }

  // user is logged in, but don't have a test account, allow them to create one
  if (!extdev) {
    return (
      <Anchor href="/extdev" className={styles.button}>
        Create test account
      </Anchor>
    );
  }

  const onToggle = async () => {
    $testMode.set(!testMode);
  };

  return (
    <div>
      <Toggle
        label="Test mode"
        checkedLabel="Disable test mode"
        uncheckedLabel="Enable test mode"
        checked={testMode}
        onClick={onToggle}
      />
    </div>
  );
};
