import { useStore } from "@nanostores/react";
import { Popover } from "@sumup-oss/circuit-ui";
import { type FC, useState } from "react";

import { ExternalLink } from "@sumup-oss/icons";
import { $merchant } from "src/store/merchant";
import { $user } from "src/store/user";

import styles from "./styles.module.css";

export const Account: FC = () => {
  const { data: user, loading: userLoading } = useStore($user);
  const { data: merchant, loading: merchantLoading } = useStore($merchant);
  const [isOpen, setIsOpen] = useState(false);

  if (userLoading || merchantLoading || !user) {
    return (
      <div className={styles.login}>
        <a href={"/auth/login"}>Login</a>
      </div>
    );
  }

  return (
    <div
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      className={styles.toggleButton}
    >
      <Popover
        className={styles.popoverContainer}
        component={() => <div className={styles.login}>Account</div>}
        onToggle={() => {}}
        isOpen={isOpen}
        placement="bottom-end"
        actions={[
          { children: user.email, disabled: true, className: styles.menuItem },
          {
            children: "Test Profile",
            disabled: merchant?.extdev,
            href: "/extdev",
            className: styles.menuItem,
          },
          { type: "divider" },
          {
            children: "API Keys",
            href: "/api-keys",
            className: styles.menuItem,
          },
          {
            children: "OAuth2 Apps",
            href: "/apps",
            className: styles.menuItem,
          },
          {
            children: "Affiliate Keys",
            href: "/affiliate-keys",
            className: styles.menuItem,
          },
          // {
          //   // @ts-expect-error - type of React.ReactNode should be accepted
          //   children: (
          //     <div className={styles.webhookWrapper}>
          //       <Body>Webhooks</Body>
          //       <Badge variant="promo">Preview</Badge>
          //     </div>
          //   ),
          //   href: "/webhooks",
          //   className: styles.menuItem,
          // },
          { type: "divider" },
          {
            children: "Dashboard",
            href: "https://me.sumup.com",
            icon: ExternalLink,
            className: styles.menuItem,
          },
          {
            children: "Logout",
            href: "/auth/logout",
            className: styles.menuItem,
          },
        ]}
      />
    </div>
  );
};
